export const columnConfig = [
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      textAlign: 'center',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'date',
    label: 'Waktu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'card',
    label: 'Kartu Debit',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-start',
  },
  {
    key: 'amount',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center text-center',
  },
]

export const columnConfigDetail = [
  {
    key: 'description',
    label: 'Deskripsi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      textAlign: 'center',
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'date',
    label: 'Waktu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '200px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center',
  },
  {
    key: 'amount',
    label: 'Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      fontWeight: 600,
      minWidth: '150px',
    },
    tdClass: 'text-black',
    class: 'p-1 text-center text-center',
  },
]

export const settings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  adaptiveHeight: true,
  touchThreshold: 5,
  swipeToSlide: true,
  swipe: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const listTopUp = [
  { id: 1, balance: 20000 },
  { id: 2, balance: 50000 },
  { id: 3, balance: 100000 },
  { id: 4, balance: 200000 },
  { id: 5, balance: 300000 },
  { id: 6, balance: 500000 },
  { id: 7, balance: 1000000 },
]

export const optionsTransactionType = [
  { value: '', name: 'Semua' },
  { value: 'credit', name: 'Transaksi Masuk' },
  { value: 'debit', name: 'Transaksi Keluar' },
  { value: 'cashback', name: 'Cashback' },
]

export const getLabelStatus = value => {
  if (value === 'requested') return 'Request'
  if (value === 'approved') return 'Disetujui'
  if (value === 'rejected') return 'Ditolak'
  return ''
}

export const getClassStatus = value => {
  if (value === 'requested') return 'text-[#FBA63C]'
  if (value === 'approved') return 'text-[#34A770]'
  if (value === 'rejected') return 'text-[#E31A1A]'
  return ''
}

export const chartSpendingCategoryOptions = {
  chart: {
    type: 'donut',
  },
  responsive: [
    {
      breakpoint: 500,
      options: {
        chart: {
          width: '280px',
        },
        legend: {
          fontSize: '10px',
          markers: {
            width: 8,
            height: 8,
          },
        },
      },
    },
    {
      breakpoint: 800,
      options: {
        chart: {
          width: '300px',
        },
        legend: {
          fontSize: '10px',
          markers: {
            width: 8,
            height: 8,
          },
        },
      },
    },
    {
      breakpoint: 9999,
      options: {
        chart: {
          width: '280px',
        },
        legend: {
          fontSize: '10px',
          markers: {
            width: 8,
            height: 8,
          },
          offsetX: 10,
          offsetY: -15,
        },
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'right',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  tooltip: {
    custom: opts => {
      const data = opts.w.config.series[opts.seriesIndex]
      const name = opts.w.config.labels[opts.seriesIndex]
      const amount = opts.w.config.xaxis.categories[opts.seriesIndex]

      const htmlEmptyData = '<div class="md:w-[200px] md:p-2 bg-white text-black">Belum ada data</div>'
      const htmlData = `<div class="md:w-[200px] md:p-2 bg-white text-black">
        <div class="flex items-center justify-between">
          <div class="text-[#828282] font-normal">${name}</div>
          <div class="border-left p-[2px] text-primary">${data}%</div>
        </div>
        <div v-if="data !== 1" class="font-bold">${amount}</div>
      </div>
      `
      return name !== 'Tidak Ada Spending' ? htmlData : htmlEmptyData
    },
  },
}
